.dashboardMain {
	padding: 1%;
	padding-right: 15px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	/* background: lightgrey; */
}

.graphs {
	width: 100%;
	height: 50%;
	display: flex;
	justify-content: space-evenly;
	/* align-items: center; */
	gap: 16px;
}

.card {
	flex: 3;
	min-height: 100%;
	aspect-ratio: 300/241;
	padding: 2%;
	padding-bottom: 0;
	background-color: white;
	border-radius: 8px;
}

.pieCharts {
	flex: 2;
	min-height: 100%;

	display: flex;
	flex-direction: column;
	gap: 16px;
}

.pieCard {
	padding: 8%;
	background-color: white;
	border-radius: 8px;

	display: flex;
	flex-direction: column;
}

.halfCard {
	padding: 8%;
	background-color: white;
	border-radius: 8px;

	display: flex;
	justify-content: space-between;
}

.recentTable {
	width: 100%;
}

.pieChartDiv {
	display: flex;
	flex-direction: column;
	/* max-height: 310px; */
}

.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.loader {
	border: 8px solid #f3f3f3;
	/* Light gray */
	border-top: 8px solid rgb(61, 79, 87);
	/* Blue */
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
