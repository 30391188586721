body {
	margin: 0;
	font-family: Inter Tight, -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.line-graph {
	width: 100% !important;
}

.pie-graph {
	width: 100% !important;
	height: 100% !important;
}

input::-ms-reveal,
input::-ms-clear {
	display: none;
}
