.fullPage {
	height: 92%;
	padding: 2% 10%;

	display: flex;
	flex-direction: column;
	/* font-family: Inter Tight; */
}

.header {
	flex: 5%;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header span {
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 500;
	line-height: 2rem;
}

.dropdown {
	width: 9.375rem;
	padding: 0.5rem 0.75rem;
	border-radius: 0.5rem;
	border: 1px solid #cccccc;
	gap: 0.5rem;
}

.body {
	height: 92%;

	display: flex;
	flex-direction: row;
	gap: 14px;
}

.card {
	border-radius: 8px;
	background-color: white;

	display: flex;
	flex-direction: column;
}

.type_selector {
	display: flex;
	gap: 4px;
	position: relative;
}

.type_button {
	flex: 1;
	min-height: 36px;
	cursor: pointer;
	text-align: center;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	position: relative;
	z-index: 1;
}

.type_selector::before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 50%;
	height: 2px;
	background-color: #0993cf;
	transform-origin: left;
	transition: transform 0.3s ease-in-out;
}

.type_button:nth-child(2) {
	z-index: 2;
}

.type_selector.active::before {
	transform: translateX(100%);
}

.active {
	color: #1c1c1c;
}

.search {
	min-height: 24px;
	margin: 3%;
	border-radius: 8px;
	border: 1px solid #cccccc;
	padding: 8px 12px 8px 12px;
	gap: 8px;
}

/* Inside your styles.module.css */

.chatMain {
	overflow-y: auto;
	display: flex;
	overflow-x: hidden;
	position: relative;
}

.chatSelector {
	width: 100%;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
}

.slideInLeft {
	transform: translateX(-100%);
}

.slideInRight {
	transform: translateX(100%);
}

.room_row {
	cursor: pointer;
	min-height: 72px;

	display: flex;
}

.room_row_name {
	flex: 3;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.name {
	color: #1c1c1c;

	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem;
}

.order {
	color: #848484;

	font-size: 0.75rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1rem;
}

.latest_time {
	flex: 1;

	display: flex;
	justify-content: center;
	align-items: center;

	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #848484;
}

.chat {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.chat_head {
	border-radius: 8px 8px 0px 0px;
	padding: 0.875rem;
	background-color: #e1e9ed;

	display: flex;
}

.chat_head_img {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.chat_head_details {
	flex: 5;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.chat_head_name {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #1c1c1c;
}

.chat_head_order {
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #0993cf;
}

.chat_head_time {
	flex: 3;

	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #848484;

	display: flex;
	justify-content: center;
	align-items: center;
}

.chat_body {
	height: 82%;
	min-height: 0; /* Allow the element to shrink below its content size */
	overflow-y: auto; /* Enable scrolling if content overflows */
	padding: 1%;

	display: flex;
	flex-direction: column;
}

.chatBubble {
	max-width: 40%;
	word-wrap: break-word;
	margin-bottom: 10px;
	padding: 0.625rem;
	border-radius: 0.5rem;
	/* display: flex; */
}

.adminBubble {
	color: #ffffff;
	background: var(--Primary, #0993cf);
}

.otherBubble {
	color: #848484;
	background: var(--Light-Border, #f2f2f2);
}

.right_message {
	justify-content: flex-end;
}

/* Styles for the chat input container */
.chat_input {
	height: auto; /* Allow the element to grow based on content */
	width: 96%;
	margin: 0 2%;
	border-top: 1px solid #f2f2f2;
	max-height: 25%; /* Limit maximum height */
	overflow-y: hidden; /* Hide scrollbar initially */
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.Mui-focused {
	border: 1px solid green;
}

/* Styles for the textarea inside chat input */
.chat_input_text {
	border: none;
	resize: none;
	font-family: inherit;
	width: 100%;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #1c1c1c;
	/* Optional: Add padding or margin for better appearance */
	padding: 8px;
	margin-right: 8px;
	transition: height 0.3s ease; /* Add smooth transition for height changes */
}

/* Apply styles to chat_input_text when it exceeds 25% height */
.chat_input_text.overflow {
	overflow-y: auto; /* Enable scrollbar */
	height: 25%; /* Limit height to 25% */
}

.empty_chat {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #1c1c1c;
}

.empty_chat div {
	text-align: center;
}

@media (max-width: 1240px) {
	.header span {
		font-size: 1.25rem;
	}
}
