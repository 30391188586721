.horizon {
	width: 100%;
	height: 100vh;
	display: flex;
}

.navbar {
	width: 13%;
	min-width: 124px;
}

.main_page {
	width: 87%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background: lightgrey;
}

.header_main {
	height: 4%;
	min-width: auto;
	padding: 1%;
	background: #3d4f57;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header_title {
	font-size: 1.125rem;
	font-weight: 600;
	line-height: 28px;
	letter-spacing: 0em;
	text-align: center;
	color: #ffffff;
}

.userSpace {
	height: 100%;
	display: flex;
	align-items: center;
	gap: 8px;

	font-size: 0.875rem;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	color: #ffffff;
}

.header_profile {
	height: 100%;
	display: flex;
	align-items: center;
	gap: 8px;
}

::-webkit-scrollbar {
	width: 0.45rem; /* Adjust the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
	background-color: rgb(0, 0, 0, 0); /* Color of the scrollbar thumb */
	border-radius: 45%;
}

.outletContainer {
	height: 100vh;
	overflow-y: auto;
}

.outletContainer:hover::-webkit-scrollbar-thumb {
	background-color: rgba(100, 100, 112, 0.505);
}

@media only screen and (max-width: 1370px) {
	.header_title {
		font-size: 0.875rem;
		font-weight: 500;
	}

	.userSpace {
		gap: 8px;

		font-size: 0.7rem;
		font-weight: 400;
	}
}
