.subCatName {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.subCatEdit {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #cccccc;
}

.subCatInput {
	width: 60%;
	border: none;
	/* border-bottom: 1px solid #cccccc; */

	/* font-family: Inter Tight; */
	color: #848484;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.subCatInput:focus {
	outline: none;
	border: none;
}
