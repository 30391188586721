.fullPage {
	height: -webkit-fill-available;
	padding: 1% 10%;
	/* font-family: Inter Tight, sans-serif; */
	overflow-y: hidden;
}

.header {
	padding: 2% 0;
	display: grid;
	grid-template-columns: 10% 45% 8% auto;
	/* Adjust as needed */
	align-items: center;
	gap: 16px;

	font-size: 1.5rem;
	font-weight: 500;
	line-height: 2rem;
}

.riderInput {
	max-height: 40px;
	padding: 8px 12px;
	border-radius: 8px;
	gap: 8px;
	border: 1px solid #cccccc;
	/* Add any additional styles as needed */

	/* font-family: Inter Tight, sans-serif; */
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #1c1c1c;
}

.searchButton {
	cursor: pointer;
	max-height: 40px;
	padding: 8px 12px;
	border-radius: 8px;
	gap: 8px;
	border: 1px solid #cccccc;
	background-color: #0993cf;

	/* font-family: Inter Tight, sans-serif; */
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: white;
}

.selectContainer {
	cursor: pointer;
	height: 2.5rem;
	padding: 0.5rem 0.75rem;
	width: 9.375rem;
	gap: 0.25rem;
	background-color: white;
	border: 1px solid #cccccc;
	border-radius: 8px !important;
	/* font-family: "Inter Tight"; */
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem;
}

/* Style for the MUI Select component */
.selectContainer div {
	width: auto; /* Allows the Select component to adjust its width based on content */
	min-width: 150px; /* Set a minimum width to prevent it from collapsing completely */
	margin-left: 16px; /* Adjust the margin as needed */
}

/* Style for the MenuItem component within the Select */
.menuItem {
	/* font-family: "Inter Tight", sans-serif; */
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.body {
	height: 90%; /*make margin-bottom for fullPage*/
	width: 100%;
	overflow-y: auto;
	display: grid;
	grid-template-columns: 49% 49%;
	gap: 2%;
}

.dropdown {
	width: 120px;
	height: 40px;
	padding: 8px 12px;
	border-radius: 8px;
	border: 1px solid #cccccc;
	gap: 8px;
}

.card {
	height: fit-content;
	padding: 2rem;
	border-radius: 0.5rem;
	background-color: white;

	display: flex;
	flex-direction: column;

	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem;
}

.imageDetail {
	display: flex;
	align-items: flex-start;
	gap: 2rem;
	align-self: stretch;
}

.attribute {
	max-height: 72px;
	padding: 4% 0;

	border-bottom: 1px solid #f2f2f2;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.riderDetails {
	padding-bottom: 4%;
	flex: 1;

	border-bottom: 1px solid #f2f2f2;
}

.attriName {
	color: #848484;
}

.attriData {
	color: #1c1c1c;

	display: flex;
	justify-content: space-between;
}

@media only screen and (max-width: 1240px) {
	.header {
		font-size: 1.25rem;
	}

	.card {
		font-size: 0.75rem;
		font-weight: 500;
	}
}

.nameNumber {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.zohebName {
	display: flex;
	align-items: center;
	gap: 1rem;
	align-self: stretch;
}

.altValue {
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
}

.verifiedBlock {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.greenCheck {
	width: 1rem;
	height: 1rem;
}

.numberStyle {
	display: flex;
	flex-direction: column;
	padding: 1rem 0rem;
	gap: 0.25rem;
	align-self: stretch;
}

.numberStyleName {
	display: flex;
	align-items: center;
	gap: 1rem;
	align-self: stretch;
}

.numberStyleValue {
	display: flex;
	align-items: center;
	gap: 1rem;
	align-self: stretch;
}

.otherDetail {
	display: flex;
	flex-direction: column;
	padding: 1rem 0rem;
	gap: 0.25rem;
	align-self: stretch;
	border-bottom: 1px solid var(--Light-Border, #f2f2f2);
	background: linear-gradient(0deg, #fff 0%, #fff 100%), #f9f9f9;
}

.subHeading {
	color: #848484;
}

.subHeadingValue {
	color: #1c1c1c;
}

.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.loader {
	border: 8px solid #f3f3f3;
	/* Light gray */
	border-top: 8px solid rgb(61, 79, 87);
	/* Blue */
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
}

.backGround {
	padding: 2% 14%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	/* font-family: Inter Tight; */
}

.backbutton {
	font-size: 24px;
	font-weight: 500;
	line-height: 32px;
	letter-spacing: 0em;
	display: flex;
	align-items: center;
	gap: 10px;
}

.card {
	border-radius: 8px;
	padding: 32px;
	background-color: #ffffff;
}

.cardHeader {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.cardTitle {
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	color: #0993cf;
}

.cardDate {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #848484;
}

.priceTitle {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #1c1c1c;
	margin-top: 20px;
}

.priceTable {
	width: 100%;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #848484;
	margin-bottom: 2%;
}

.priceVal {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #1c1c1c;
	text-align: right;
}

.editButton {
	display: inline-block;
	margin-right: 5px;
}

.total {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	border-top: 1pt solid #f2f2f2;
	border-bottom: 1pt solid #f2f2f2;
	padding: 3% 0;
	display: flex;
	justify-content: space-between;
}

.items {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #1c1c1c;
	margin-top: 2%;
}

.itemsTable {
	width: 100%;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #848484;
	margin-bottom: 2%;
}

.pickup {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	border-top: 1pt solid #f2f2f2;
	border-bottom: 1pt solid #f2f2f2;
	padding: 3% 0;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: space-between;
}

.pickupVal {
	font-size: 14px;
	line-height: 20px;
	color: #848484;
}

.addressBar {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	border-top: 1pt solid #f2f2f2;
	border-bottom: 1pt solid #f2f2f2;
	padding: 3% 0;
	display: flex;
	flex-direction: column;
}

.addressTag {
	width: 49px;
	margin-bottom: 2%;
	padding: 3px 2px;
	border-radius: 8px;
	text-align: center;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	color: #ffffff;
}

.userInfo {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #1c1c1c;
	margin-bottom: 1%;
}

.userContact {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #1c1c1c;
	margin-bottom: 1%;
}

.userAddress {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #848484;
}

.orderActions {
	border-radius: 8px;
	padding: 32px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
}

.actionTitle {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	margin-top: 20px;
	color: #1c1c1c;
}

.statusDropdown {
	margin-top: 1%;
	color: 0993CF;
	border-bottom: 1pt solid #f2f2f2;
	padding: 3% 0;
	display: flex;
	justify-content: space-between;

	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: center;
}

.assign {
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	cursor: pointer;
	margin-top: 2%;
	border-bottom: 1pt solid #f2f2f2;
	padding-bottom: 1%;
}

.notAssign {
	margin-top: 2%;
	border-bottom: 1pt solid #f2f2f2;
	padding-bottom: 1%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	color: #848484;
}

.riderDetails {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	text-wrap: nowrap;
}

.chatButton {
	border-radius: 8px;
	background-color: #0993cf;
	color: #f2f2f2;
	cursor: pointer;
	text-align: center;
	padding: 0.25rem 0.5rem;
}
