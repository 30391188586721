.fullModal {
	max-height: 60%;
	width: 30%;
	background-color: white;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
}

.header {
	padding: 2% 3%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	background-color: #3d4f57;
	color: #ffffff;

	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
}

.body {
	/* flex-grow: 1; */

	height: 82%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;

	padding: 2% 4%;
}

.inputDiv {
	display: flex;
}

.bodyFirst {
	overflow-y: auto;
	max-height: 100%;
	display: flex;
	flex-direction: column;
}

.riders {
	overflow-y: auto;
	margin-top: 2%;
	display: flex;
	flex-direction: column;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #848484;
}

.riderTag {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1pt solid #f2f2f2;
}

.riderIN {
	/*Image & Name*/
	display: flex;
	gap: 1rem;
	justify-content: space-between;
	align-items: center;
	text-wrap: nowrap;
}

.riderIMG {
	border-radius: 50%;
	width: 2rem;
	height: 2rem;
}

.riderInput {
	flex: 1;
	border-radius: 8px;
	margin-top: 2%;
	padding: 8px 12px;
	border: 1px solid #cccccc;
	/* Add any additional styles as needed */

	/* font-family: Inter Tight, sans-serif; */
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.submitButtonDiv {
	margin-top: 4%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.submitButton {
	width: 100%;
	cursor: pointer;
	border: none;
	border-radius: 8px;
	background-color: #0993cf;
	padding: 8px;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
}
