.fullPage {
	padding: 1%;
}

.card {
	padding: 32px;
	border-radius: 8px;
	background-color: white;

	display: flex;
	flex-direction: column;
	gap: 32px;
}

.header {
	display: grid;
	grid-template-columns: 4% 46% 10% 5% auto;
	/* Adjust as needed */
	align-items: center;
	gap: 16px;

	/* font-family: Inter Tight, sans-serif; */
	font-weight: 500;
	font-size: 1.5rem;
	line-height: 2rem;
}

.header select {
	justify-self: right;
}

.searchButton {
	cursor: pointer;
	max-height: 40px;
	padding: 0.375rem 0.25rem;
	border-radius: 8px;
	gap: 8px;
	border: 1px solid #cccccc;
	background-color: #0993cf;

	font-family: Inter Tight, sans-serif;
	color: white;
}

.orderInput {
	margin-left: 2rem;
	max-height: 40px;
	padding: 0.375rem 0.45rem;
	border-radius: 8px;
	border: 1px solid #cccccc;
	gap: 8px;
	border: 1px solid #cccccc;
	/* Add any additional styles as needed */

	/* font-family: Inter Tight, sans-serif; */
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.dropdown {
	width: auto;
	max-height: 40px;
	padding: 0.375rem 0.25rem;
	border-radius: 8px;
	border: 1px solid #cccccc;
	gap: 8px;

	/* font-family: "Inter Tight"; */
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem;
	color: #aeaeae;
}

.dropdown option {
	color: #1c1c1c;
}

.pagination_container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.dropdown_container {
	width: 71px;
	height: 40px;
	padding: 8px, 12px, 8px, 12px;
	border-radius: 8px;
	border: 1px;
	gap: 8px;
	border: 1px solid #cccccc;

	/* font-family: Inter Tight; */
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: #848484;
}

.buttons_container {
	width: 15%;
	display: flex;
	gap: 2%;
	justify-content: space-between;
}

.buttons_container button {
	width: 15%;
	aspect-ratio: 1;
	border-radius: 8px;
	padding: 8px;
	border: 1px solid #cccccc;

	/* font-family: Inter Tight; */
	font-size: 14px;
	font-weight: 600;
	color: #848484;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: center;
}

.assignButton {
	color: #0993cf;
}

.assignButton:hover {
	text-decoration: underline;
}

.notassignButton {
	color: #848484;
}

.rider {
	display: flex;
	align-items: center;
}

.riderImage {
	width: 2rem;
	height: 2rem;
	flex-shrink: 0;
	margin-right: 1rem;
}

.dropdownStatus {
	/* font-family: Inter Tight; */
	font-size: 0.75rem;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	border: 0;
	background-color: transparent;
}

@media (max-width: 1240px) {
	.header {
		font-size: 1.25rem;
	}

	.orderInput {
		font-size: 0.675rem;
	}

	.dropdown {
		font-size: 0.675rem;
	}
}
