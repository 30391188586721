.fullModal {
	width: 45%;
	max-height: 60%;
	overflow-y: auto;
	background-color: white;

	display: flex;
	flex-direction: column;

	/* font-family: Inter Tight; */
	box-sizing: border-box;
}

.header {
	padding: 2% 3%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	background-color: #3d4f57;
	color: #ffffff;

	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
}

.body {
	/* flex-grow: 1; */

	height: 82%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	gap: 1.25rem;

	padding: 4%;

	font-size: 0.875rem;
}

.pair {
	display: flex;
	flex-direction: column;
}

.submitButtonDiv {
	margin-top: 4%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
}

.button {
	width: stretch;
	cursor: pointer;
	border-radius: 8px;
	padding: 8px;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}

.cancelButton {
	background-color: #ffffff;
	color: #848484;
	border: 1px solid #cccccc;
}

.submitButton {
	background-color: #0993cf;
	color: #ffffff;
	border: none;
}
