.fullModal {
	border: none;
	min-width: 560px;
	background-color: white;

	display: flex;
	flex-direction: column;

	/* font-family: Inter Tight; */
}

.header {
	height: 13%;
	padding: 2% 3%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	background-color: #3d4f57;
	color: #ffffff;

	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
}

.body {
	display: flex;
	flex-direction: column;
	padding: 2% 4%;
	padding-bottom: 5%;
	justify-content: space-between;
}

.bodyFirst {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #848484;
}

.deleteMessage {
	align-self: center;
	max-width: 363px;
	text-align: center;
}

.deleteHead {
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	color: "#1c1c1c";
}

.deleteBody {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.inputBar {
	margin-top: 1%;
	border-radius: 8px;
	padding: 8px 12px;
	border: 1px solid #cccccc;
	/* Add any additional styles as needed */

	/* font-family: Inter Tight, sans-serif; */
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.button {
	cursor: pointer;
	width: 50%;
	border-radius: 8px;
	padding: 8px;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}
