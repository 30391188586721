.header {
	flex: 1;

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 0.5rem;
}

.cardTitle {
	/* font-family: Inter Tight; */
	color: #1c1c1c;
	font-size: 1.125rem;
	font-weight: 500;
	line-height: 1.75rem;
	align-self: center;
}

/*second*/
.second {
	display: flex;
	justify-content: space-between;
	padding-bottom: 15px;
}

.second h1 {
	/* font-family: Inter Tight, sans-serif; */
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	align-self: center;
}

.third {
	height: -webkit-fill-available;
}

.segregate {
	display: flex;
}

.valuePair {
	display: flex;
	flex-direction: column;
	justify-content: left;
}

.typeName {
}

.valuePair h1 {
	color: #1c1c1c;

	/* Medium/14 M */
	font-family: "Inter Tight";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem; /* 142.857% */
	margin: 0;
	text-align: left;
}

.valuePair h2 {
	color: #848484;
	font-size: 0.625rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem;

	margin: 0;
	/* font-family: Inter Tight, sans-serif; */
	text-align: left;
}

.filterStyle {
	width: 5.25rem;
	border-radius: 8px;
	color: #848484;
	height: 1.5rem;
	text-align: left;
	font-size: 0.75rem;
	font-weight: 400;
}

.body {
	flex: 3;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-width: 25px;
}

.secondAltFirst {
	width: 50%;

	display: flex;
	flex-direction: column;
}

.secondAltText {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.secondAltPie {
	flex: 1;
}

/*Table*/
.tableHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-bottom: 2%;
}

.tableHeader h1 {
	margin: 0;
	/* font-family: Inter Tight, sans-serif; */
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	align-self: center;
}

tbody {
	overflow-y: scroll;
}

.pieBigVal {
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 2rem;
}

.pieSmallVal {
	display: flex;
	gap: 1rem;
	/* width: 80%; */
}

.pieContainer {
	width: 40%;
}

@media only screen and (max-width: 1240px) {
	.cardTitle {
		font-size: 0.875rem;
	}

	.header {
		padding-bottom: 0.25rem;
	}

	.valuePair h2 {
		font-size: 0.6rem;
	}
	.valuePair h1 {
		font-size: 0.675rem;
	}
}

.rider {
	display: flex;
	align-items: center;
}

.riderImage {
	width: 2rem;
	height: 2rem;
	flex-shrink: 0;
	margin-right: 1rem;
}
