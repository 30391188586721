.fullModal {
	background-color: white;

	display: flex;
	flex-direction: column;

	/* font-family: Inter Tight; */
}

.header {
	height: 13%;
	padding: 2% 3%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	background-color: #3d4f57;
	color: #ffffff;

	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
}

.body {
	flex-grow: 1;

	display: flex;
	flex-direction: column;
	padding: 2% 4%;
	padding-bottom: 5%;
	justify-content: space-between;
}

.message {
	text-align: center;
	padding: 1rem;
}

.bodyFirst {
	display: flex;
	flex-direction: column;
}

.riders {
	width: 100%;
	margin-top: 2%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #848484;
}

.riderTag {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1pt solid #f2f2f2;
}

.riderIN {
	/*Image & Name*/
	display: flex;
	gap: 5px;
}

.riderIMG {
	width: 20px;
	height: 20px;
}

.riderInput {
	border-radius: 8px;
	margin-top: 2%;
	padding: 8px 12px;
	border: 1px solid #cccccc;
	/* Add any additional styles as needed */

	font-family: Inter Tight, sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.submitButton {
	border: none;
	border-radius: 8px;
	background-color: #0993cf;
	padding: 8px;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	cursor: pointer;
}
