.fullModal {
	background-color: white;
	box-shadow: -0.2rem 0.2rem rgba(0, 0, 0, 0.2);

	min-width: 10rem;
	min-height: 3rem;
	border-radius: 8px;

	display: flex;
	flex-direction: column;

	font-family: Inter Tight;
}

.button {
	cursor: pointer;

	padding: 0.5rem;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #848484;

	display: flex;
	align-items: center;
	text-align: center;
	gap: 1rem;
}

@media only screen and (max-width: 1370px) {
	.fullModal {
		min-width: 8rem;
		min-height: 1rem;
	}

	.button {
		font-size: 0.7rem;
		padding: 0.25rem;
	}
}
