.fullPage {
	height: -webkit-fill-available;
	padding: 1% 10%;
	/* font-family: Inter Tight, sans-serif; */
	overflow-y: auto;
}

.header {
	display: flex;
	align-items: center;
	text-align: center;
	padding: 1rem 0;

	gap: 16px;

	font-size: 1.5rem;
	font-weight: 500;
	line-height: 2rem;
}

.body {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.rowTitle {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #f2f2f2;
	padding-bottom: 1rem;
}

.nameEmail {
	padding-top: 1rem;
	gap: 2rem;
	display: flex;
	margin-bottom: 1rem;
}

.pair {
	display: flex;
	flex-direction: column;
}

.pair span {
	font-size: 0.875rem;
}

.titleFont {
	font-weight: 500;
	font-size: 1.125rem;
	line-height: 2rem;
}

.editButton {
	color: #0993cf;
	text-decoration: underline;
	cursor: pointer;
	font-size: 0.875rem;
}

.card {
	background-color: #ffffff;
	border-radius: 8px;
	border: none;
	padding: 2rem;
}

.valueTitle {
	color: #848484;
}

.passFirst {
	display: flex;
	gap: 2rem;
	margin: 1rem 0;
}

@keyframes shake {
	0%,
	100% {
		transform: translateX(0);
	}
	25%,
	75% {
		transform: translateX(-10px);
	}
	50% {
		transform: translateX(10px);
	}
}

.shake {
	animation: shake 0.5s ease-in-out;
}

.submitContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.button {
	border-radius: 8px;
	width: 4.75rem;
	height: 2.4rem;
	cursor: pointer;

	/* font-family: Inter Tight; */
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0em;
}

.cancel {
	margin-right: 1rem;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	color: #848484;
}

.submit {
	background-color: #0993cf;
	border: none;
	color: #ffffff;

	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 1240px) {
	.header {
		font-size: 1rem;
		font-weight: 450;
		gap: 8px;
	}
}

.imageLabel {
	cursor: pointer;
	position: relative;
	top: 0;
	left: 0;
}

.imageWrapper {
	position: relative;
	top: 0;
	left: 0;
}

.imgIcon {
	position: absolute;
	bottom: 0;
	right: 2%;
}

.hidden {
	position: absolute;
	top: -9999px;
	left: -9999px;
	opacity: 0;
	visibility: hidden;
}
