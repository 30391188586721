.fullPage {
	height: -webkit-fill-available;
	padding: 2% 10%;
	/* font-family: Inter Tight, sans-serif; */
	overflow-y: hidden;
}

.header {
	display: flex;
	align-items: center;
	text-align: center;
}

.header span {
	margin-right: 4rem;
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 500;
	line-height: 2rem;
}

.search {
	width: 26.3125rem;
	height: 2.5rem;
	padding: 0.5rem 0.75rem;
	border-radius: 0.5rem;
	border: 1px solid var(--Border, #ccc);
	background: var(--White-BG, #fff);
	/* font-family: Inter Tight, sans-serif; */
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem;
}

.body {
	height: 90%;
	overflow-y: auto;
	margin-top: 1.5rem;
	width: 100%;

	display: grid;
	grid-template-columns: 49% 49%;
	gap: 2%;
}

.card {
	height: fit-content;
	display: flex;
	padding: 2rem;
	flex-direction: column;
	border-radius: 0.5rem;
	background: #fff;

	font-size: 0.875rem;
}

.cardHeader {
	display: flex;
	gap: 2rem;
}

.reqStyle {
	flex: 1;
	padding: 1rem 0rem;
	gap: 0.25rem;
	border-bottom: 1px solid #f2f2f2;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.pair {
	display: flex;
	flex-direction: column;
}

.imageWrap {
	width: 7.5rem;
	height: 7.5rem;
	flex-shrink: 0;
}

.identify {
	width: -webkit-fill-available;
	display: flex;
	flex-direction: column;
}

.cardBody {
	display: flex;
	flex-direction: column;
}

@media only screen and (max-width: 1370px) {
	.header span {
		font-size: 1.25rem;
	}
	.search {
		font-size: 0.7rem;
	}
	.card {
		font-size: 0.7rem;
	}
}
