.sidebar {
	/* min-width: 13%; */
	background: #3d4f57;
	height: 100%;
	border-right: 1px #848484;
}
.sidebar a {
	text-decoration: none;
}
.company_logo {
	height: 6%;
	justify-content: center;
	align-items: center;
}
.company_logo img {
	width: 60%;
	height: auto;
}
.sidebar ul {
	padding: 0px 12px;
	margin: 14px 0px;
	list-style-type: none;
	gap: 20px;
}
.menu_item {
	padding: 12px;
	border-radius: 8px;
	color: white;
	cursor: pointer;

	/* font-family: Inter Tight; */
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.25rem;
}

.menu_item:hover {
	background: #0a3d73;
	color: white;
}

.menu_list {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.75rem;
}

.icon {
	display: flex;
	justify-content: center;
	align-items: center;
}

.active .menu_item {
	background: #f5f5f5;
	color: #1c1c1c;
}

.menu_item .icon {
	color: #ffffff; /* Set the default color for the icon */
}

.active .menu_item .icon {
	color: #0993cf; /* Set the color for the icon when the link is active */
}

@media only screen and (max-width: 1370px) {
	.menu_item {
		font-size: 0.7rem;
		font-weight: 500;
	}

	.menu_list {
		line-height: 1.5;
	}
}
