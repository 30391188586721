.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.loader {
	border: 8px solid #f3f3f3;
	/* Light gray */
	border-top: 8px solid rgb(61, 79, 87);
	/* Blue */
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
}

.backGround {
	padding: 2% 14%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	/* font-family: Inter Tight; */
}

.backbutton {
	font-size: 24px;
	font-weight: 500;
	line-height: 32px;
	letter-spacing: 0em;
	display: flex;
	align-items: center;
	gap: 10px;
}

.card {
	width: 50%;
	border-radius: 8px;
	padding: 32px;
	background-color: #ffffff;
}

.cardHeader {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.cardTitle {
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	color: #0993cf;
}

.cardDate {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #848484;
}

.priceTitle {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #1c1c1c;
	margin-top: 20px;
}

.priceTable {
	width: 100%;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #848484;
	margin-bottom: 2%;
}

.priceVal {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #1c1c1c;
	text-align: right;
}

.editButton {
	display: inline-block;
	margin-right: 5px;
}

.total {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	border-top: 1pt solid #f2f2f2;
	border-bottom: 1pt solid #f2f2f2;
	padding: 3% 0;
	display: flex;
	justify-content: space-between;
}

.items {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #1c1c1c;
	margin-top: 2%;
}

.itemsTable {
	width: 100%;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #848484;
	margin-bottom: 2%;
}

.pickup {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	border-top: 1pt solid #f2f2f2;
	border-bottom: 1pt solid #f2f2f2;
	padding: 3% 0;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: space-between;
}

.pickupVal {
	font-size: 14px;
	line-height: 20px;
	color: #848484;
}

.addressBar {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	border-top: 1pt solid #f2f2f2;
	border-bottom: 1pt solid #f2f2f2;
	padding: 3% 0;
	display: flex;
	flex-direction: column;
}

.addressTag {
	width: 49px;
	margin-bottom: 2%;
	padding: 3px 2px;
	border-radius: 8px;
	text-align: center;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	color: #ffffff;
}

.userInfo {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #1c1c1c;
	margin-bottom: 1%;
}

.userContact {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #1c1c1c;
	margin-bottom: 1%;
}

.userAddress {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #848484;
}

.orderActions {
	border-radius: 8px;
	padding: 32px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
}

.actionTitle {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	margin-top: 20px;
	color: #1c1c1c;
}

.statusDropdown {
	margin-top: 1%;
	color: 0993CF;
	border-bottom: 1pt solid #f2f2f2;
	padding: 3% 0;
	display: flex;
	justify-content: space-between;

	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: center;
}

.assign {
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	cursor: pointer;
	margin-top: 2%;
	border-bottom: 1pt solid #f2f2f2;
	padding-bottom: 1%;
}

.notAssign {
	margin-top: 2%;
	border-bottom: 1pt solid #f2f2f2;
	padding-bottom: 1%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	color: #848484;
}

.riderDetails {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	text-wrap: nowrap;
}

.chatButton {
	border-radius: 8px;
	background-color: #0993cf;
	color: #f2f2f2;
	cursor: pointer;
	text-align: center;
	padding: 0.25rem 0.5rem;
}
