/*Welcome Template*/
.inputSide {
	flex: 1;

	display: flex;
	justify-content: center;
	align-items: center;
}

.adSide {
	background-color: #3d4f57;
	flex: 1;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5%;
}

.adImages {
	padding: 0 2rem 0 2rem;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 12.5px;
	/* max-width: 916px;
    width: calc(100% - 20px); */
}

.imagerow {
	width: 80%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12.5px;
}

.testimage {
	/* max-width: 100%; */
	max-width: 28%;
	height: auto;
}

.formMain {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	margin-bottom: 5%;
}

.formMain input {
	/*why is this not taking the border radius??*/
	border-radius: 8px;
}

.adText {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;
}

.freshness {
	/* font-family: Inter Tight, sans-serif; */
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 32px;
	color: #ffffff;
}

.experience {
	text-align: center;
	/* font-family: Inter Tight, sans-serif; */
	font-size: 0.85rem;
	font-weight: 300;
	line-height: 24px;
	color: #ffffff;
}

/* input {
	border-radius: 8px;
} */

input[name="email"] {
	/* border-radius: 8px; */
}

/*WelcomeComp*/

.welcomeComp {
	width: 80%;
	display: flex;
	flex-direction: column;

	font-family: Inter Tight, sans-serif;
}

.topText {
	display: flex;
	flex-direction: column;
	gap: 16px;

	margin-bottom: 5%;
}

.welcome {
	/* font-family: Inter Tight, sans-serif; */
	font-size: 1.75rem;
	font-weight: 500;
	line-height: 40px;
}

.welcomeMessage {
	font-size: 1rem;
	font-weight: 400;
	line-height: 24px;
	color: #848484;
}

.resetDiv {
	display: flex;
	flex-direction: column;
}

.resetTitle {
	margin-bottom: 1rem;
}

.resetMessage {
	margin-bottom: 2rem;
	/* font-family: Inter Tight, sans-serif; */
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #848484;
}

.emailClass {
	border-color: #cccccc;
	border-style: 1px;
	height: 15%;
}

.password-input {
	position: relative;
	display: flex;
	align-items: center;
}
.password-input input[type="password"] {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	transition: border 0.3s;
}
.password-input input[type="password"]:focus {
	border-color: #007bff;
	outline: none;
}
.password-toggle {
	position: absolute;
	right: 10px;
	cursor: pointer;
}
.password-icon {
	cursor: pointer;
}

.formMain > a {
	text-decoration: none;
}

.forgotPassword {
	cursor: pointer;
	text-decoration: none;
	/* font-family: Inter Tight, sans-serif; */
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #0993cf;
}

.forgotPasswordTitle {
	/* font-family: Inter Tight, sans-serif; */
	font-weight: 500;
	font-size: 32px;
	line-height: 40px;
}

.forgotMessage {
	/* font-family: Inter Tight, sans-serif; */
	font-weight: 400;
	size: 16px;
	line-height: 24px;
}

.emailAddressInput {
	border-radius: 8px;
	width: 100%;
	height: 2.5rem;
	border: 1px solid #cccccc;
	font-family: Inter Tight, sans-serif;
}

@keyframes shake {
	0%,
	100% {
		transform: translateX(0);
	}
	25%,
	75% {
		transform: translateX(-10px);
	}
	50% {
		transform: translateX(10px);
	}
}

.shake {
	animation: shake 0.5s ease-in-out;
}

/* 1366 x 768 standard... default is 1920 x 1080 */
@media (max-width: 1240px) {
	.adText {
		gap: 5px;
	}
	.freshness {
		font-size: 0.875rem;
	}
	.experience {
		font-size: 0.6rem;
		line-height: 1rem;
	}

	.welcome {
		font-size: 1.2rem;
		line-height: 1rem;
	}

	.welcomeMessage {
		font-size: 0.6rem;
		line-height: 1rem;
	}
}
