.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.loader {
	border: 8px solid #f3f3f3;
	/* Light gray */
	border-top: 8px solid rgb(61, 79, 87);
	/* Blue */
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
}

.greyed {
	color: #aeaeae !important;
	cursor: auto !important;
}

.backGround {
	height: 92%;
	padding: 2% 10%;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.title {
	font-weight: 500;
	font-size: 1.5rem;
	line-height: 32px;
}

.addService {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #0993cf;
	text-decoration: underline;
	cursor: pointer;
}

.services {
	width: 50%;

	display: flex;
	flex-direction: column;
	gap: 8px;
}

.laundryTitle {
	display: flex;
	justify-content: space-between;
}

.laundryFont {
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.75rem;
}

.editButton {
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 1.25rem;
	color: #0993cf;
	text-decoration: underline;
	cursor: pointer;
}

.priceStyle {
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem; /* 150% */

	display: flex;
	justify-content: space-between;
}

.card {
	padding: 1.45rem;
	border-radius: 8px;
	background-color: white;

	display: flex;
	flex-direction: column;
	/* gap: 1.5rem; */
}

.categoryLabel {
	display: flex;
	justify-content: space-between;
	padding: 1.5rem 0;
}

.options {
	display: flex;
	align-items: center;
	flex-direction: row;
	gap: 16px;

	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #0993cf;
	text-decoration: underline;
}

.options div {
	cursor: pointer;
}

.options.greyed div {
	cursor: auto;
	color: #aeaeae !important;
}

.details {
	width: 50%;
}

.catArrList {
	width: stretch;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.catArrList::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.catArrList {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.emptySub {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.watermark {
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.subList {
	height: 100%;

	display: flex;
	flex-direction: column;
}

.activeSubCat {
	justify-content: space-between;
	gap: 4%;
}

.subCatList {
	width: 100%;
	height: stretch;
	position: absolute;

	display: flex;
	flex-direction: column;
	gap: 1rem;
	overflow-y: auto;
	padding: 0.5rem 0;
}

.subCatContainer {
	height: fill;
	position: relative;
}

.subCat {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	color: #848484;
}

.subCatInputName {
	/* margin-left: 5%; */
	width: 100%;
	padding: 8px 12px;
	border: none;
	border-bottom: 1px solid #cccccc;
	/* Add any additional styles as needed */

	/* font-family: Inter Tight, sans-serif; */
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.subCatInputPrice {
	/* margin-left: 2%;
	margin-right: 2%;
	width: 35%; */
	width: 100%;
	padding: 8px 12px;
	border: none;
	border-bottom: 1px solid #cccccc;
	/* Add any additional styles as needed */

	/* font-family: Inter Tight, sans-serif; */
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #0993cf;
}

.priceDel {
	padding-left: 8%;
	max-width: 30%;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.priceContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #cccccc;
}

.priceContainer:focus-within {
	border-bottom: 1px solid #1c1c1c; /* Change to your desired color */
}

.editPrice {
	width: 60%;
	border: none;
	/* border-bottom: 1px solid #cccccc; */

	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
}

.editPrice:focus {
	outline: none;
	border: none;
}

.button {
	font-family: Inter Tight;
	cursor: pointer;
	width: 50%;
	border-radius: 8px;
	padding: 8px;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}

@media (max-width: 1240px) {
	.title {
		font-size: 1.25rem;
	}

	.subList {
		height: 86%;
	}
}

.subCatMain {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	gap: 1%;
}

.rotated {
	transform: rotate(90deg);
}

.catBorderBottom {
	border-bottom: 1px solid #cccccc;
}
