.loaderContainer {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader {
	border: 0.25rem solid #f3f3f3;
	/* Light gray */
	border-top: 0.25rem solid rgb(61, 79, 87);
	/* Blue */
	border-radius: 50%;
	width: 50%;
	height: 50%;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
