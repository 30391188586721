.fullModal {
	width: 30%;
	max-height: 60%;
	background-color: white;

	display: flex;
	flex-direction: column;

	font-family: Inter Tight;
	box-sizing: border-box;
}

.header {
	padding: 2% 3%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	background-color: #3d4f57;
	color: #ffffff;

	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
}

.body {
	/* flex-grow: 1; */

	height: 82%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2% 4%;
}

.cropContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin-bottom: 1rem;
}

.button {
	border-radius: 8px;
	width: 4.75rem;
	height: 2.4rem;
	cursor: pointer;

	/* font-family: Inter Tight; */
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0em;
}

.cancel {
	margin-right: 1rem;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	color: #848484;
}

.submit {
	background-color: #0993cf;
	border: none;
	color: #ffffff;
}

.imageLabel {
	cursor: pointer;
	position: absolute;
	bottom: 0;
	right: 0;
}

.hidden {
	position: absolute;
	top: -9999px;
	left: -9999px;
	opacity: 0;
	visibility: hidden;
}

.footer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2%;
}
